import logo from './logo.svg';
import i18n from "i18next";
import classes from './App.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import { SetLanguage } from './i18n';
import IntroView from './view_intro/IntroView';
import Header from './view_header/Header';
import styles from './global_styles.css';
import { useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import CalculatorView from './view_calculator/CalculatorView';
import {AppContextProvider } from './_contexts/AppContext';
import TextView from './view_text/TextView';
import { useWindowSize } from './__hooks/useWindowSize';
import Header_2022 from './Header_2022/Header_2022';


function App() {
  const { t } = useTranslation();
  useEffect(()=>{
    document.body.classList.add("intro")   
  },[])
  
 
  const size = {w:document.documentElement.clientWidth, h:document.documentElement.clientHeight};
  size.ratio = size.w/size.h;
  const [width, height] = useWindowSize();


  let startRoute = useMatch("");
  let caclulatorRoute = useMatch("calculator");
  let caclulatorStepRoute = useMatch("calculator/:step_id");
  let imprintRoute = useMatch("imprint");
  let contactRoute = useMatch("contact");
  let informationRoute = useMatch("information");
  
  if(startRoute == null)
    document.body.classList.remove("intro")
  else
    document.body.classList.add("intro")
  
  return (
    <AppContextProvider>
      <div className={classes.root +' '+(size.ratio>1?'landscape':'portrait')} style={{'--appHeight':document.documentElement.clientHeight+"px"}}>
        {false && <Header></Header>}
        <Header_2022></Header_2022>
        <IntroView visible={startRoute!=null}></IntroView>
        {(startRoute==null) && <CalculatorView></CalculatorView>}  
        {imprintRoute != null && <TextView type="imprint"></TextView>}      
        {contactRoute != null && <TextView type="contact"></TextView>}      
        {informationRoute != null && <TextView type="information"></TextView>}      
      </div>
    </AppContextProvider>
  );
}

export default App;
