
import classes from './MapsView.module.css';

import { useTranslation, initReactI18next } from "react-i18next";
import { useContext, useState, useRef } from "react";

import { AppContext } from "../_contexts/AppContext";
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect } from "react";
import searchIcon from "./../images/searchIcon.svg";
import { useWindowSize } from '../__hooks/useWindowSize';



function calcDistance(lat1, lon1, lat2, lon2) 
{
  const R = 6371; // km
  const dLat = toRad(lat2-lat1);
  const dLon = toRad(lon2-lon1);
  const _lat1 = toRad(lat1);
  const _lat2 = toRad(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(_lat1) * Math.cos(_lat2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}



function MapsView({doneCallback, hidden, nextCallback, model}) {
  const mapRef = useRef();
  const maskRef = useRef();
  const mapServiceRef = useRef();
  const mapApiRef = useRef();
  const db = useContext(AppContext).db;
  //console.log(db.getActualStepId())
  const [width, height] = useWindowSize();
  
  const { t } = useTranslation();
  
  
  const textChanged=(e)=>{
    if(e.target.value.length>3){
      mapApiRef.current.setCenter({lat: 0, lng: 0});
      const request = {
        query: e.target.value,
        fields: ["name", "geometry"],
      };
    
      mapServiceRef.current.findPlaceFromQuery(
        request,(results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            if(results[0].geometry && results[0].geometry.location){
              if(doneCallback){
                let distance = calcDistance(results[0].geometry.location.lat(), results[0].geometry.location.lng(), model.settings.mapCenter.lat, model.settings.mapCenter.lng)
                
                doneCallback(results[0].name, {lat:results[0].geometry.location.lat(), lng:results[0].geometry.location.lng(), distance:distance})
              }
              mapApiRef.current.setCenter(results[0].geometry.location);
            }
          }
        }
      );

    }


  }
  useEffect(()=>{
    const loader = new Loader({
      apiKey: "AIzaSyBrTvhbfo22fBfNj22BQ2M9n0_-tnjrvYg",/*"AIzaSyAm470Pkbvzri6doZf1AepLIuZIopldC78",*/
      version: '3.49',
      libraries:['places']
    });
    
    loader.load().then(() => {
      let map = new window.google.maps.Map(mapRef.current, {
        center: model.settings.mapCenter,
        zoom: model.settings.mapZoom,
        disableDefaultUI:true,
        styles:[
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          }
        ]
      });
      let service = new window.google.maps.places.PlacesService(map);
      mapServiceRef.current = service;
      mapApiRef.current = map;
    });
  },[]);
  
  
  useEffect(()=>{
    setTimeout(()=>{
      const rect = maskRef.current.getBoundingClientRect();
      let square = Math.round(Math.min(rect.width, rect.height));
      
      const percent = rect.width/rect.height>1?.8:.9;
      if(rect.width/rect.height<8 && rect.width/rect.height>1/8)
        square *= 10*percent;
      else
        square = Math.round(Math.max(rect.width, rect.height));
      maskRef.current.style.setProperty('--maskSize',square+"px");
    },100);
  },[width, height]);
  
  return (
    <div className={classes.root + (hidden?' '+classes.hidden:'')}>      
      <div className={classes.header}>
        <span>
          <input type="text" placeholder={t("city.placeholder")} onInput={textChanged} onKeyDown={(e)=>{if(e.key==="Enter"){e.target.blur();nextCallback();}}}></input>
          <img src={searchIcon} alt="search" onClick={()=>nextCallback()}></img>
        </span>
      </div>
      <div className={classes.content}>
       <div ref={mapRef} className={classes.map}></div>
       <div className={classes.mask} ref={maskRef}></div>

      </div>
     
    </div>
  );
}

export default MapsView;
