
import i18n from '../i18n';
import classes from './CalculatorTile.module.css';

import bus from './../images/bus.svg'
import car from './../images/car.svg'
import car_l from './../images/car_l.svg'
import car_m from './../images/car_m.svg'
import car_s from './../images/car_s.svg'
import chio from './../images/chio.svg'
import cycle from './../images/cycle.svg'
import feet from './../images/feet.svg'
import flight from './../images/flight.svg'
import scooter from './../images/scooter.svg'
import train from './../images/train.svg'
import { useRef } from 'react';

const icons = {
  bus:bus,
  car:car,
  car_l:car_l,
  car_m:car_m,
  car_s:car_s,
  chio:chio,
  cycle:cycle,
  feet:feet,
  flight:flight,
  scooter:scooter,
  train:train
}
const parseContent = (input)=>{
  if(typeof input === 'string'){
    if(input.split('.').length===1 || icons[input.split(".")[0]] == null)
      return {type:'text', value:input};
    else{
      return {type:'icon', id:input.split(".")[0],value:<div className={classes.img + ' ' +classes[input.split(".")[0]]} style={{maskImage:'url('+icons[input.split(".")[0]]+')', WebkitMaskImage:'url('+icons[input.split(".")[0]]+')'}}  />};      
      return {type:'icon', id:input.split(".")[0],value:<img src={icons[input.split(".")[0]]} alt="icon" />};      
    }    
  }

  if(typeof input === 'object' && input !== null && !Array.isArray(input)){
  
    if(input[i18n.language]!=null){
      return parseContent(input[i18n.language]);
    }
  }
  if(Array.isArray(input) && input !== null ){
    let entries = [];
    input.map(element=>{
      entries.push(parseContent(element));
    })
    return entries;
  }
  return {type:'text', value:JSON.stringify(input)};
}

function CalculatorTile({entry, active=false, onClick=null, bigAnimation=false}) {

  const animationRef = useRef();
  const slideShowRef = useRef();

  let title = parseContent(entry.title);
  let icon =  parseContent(entry.icon);
  let iconText = parseContent(entry.iconText);
  
  

  if(Array.isArray(icon)){
    
    let ar = <div ref={slideShowRef} className={classes.slideshow} data-pos={0} data-len={icon.length} style={{'--pos':0}}>{icon.map((ico,i)=>{return <div key={i}>{ico.value}</div>})}</div>
    icon = icon[0]
    icon.value = ar;
    if(animationRef.current)
      clearInterval(animationRef.current)
    if(!bigAnimation){
      animationRef.current = setInterval(()=>{
        if(slideShowRef.current == null)
          clearInterval(animationRef.current)
        else{

          let pos = parseInt(slideShowRef.current.dataset.pos);
          let len = parseInt(slideShowRef.current.dataset.len);
          pos++;
          if(pos>=len)
            pos = 0;
          slideShowRef.current.setAttribute("data-pos", pos);
          slideShowRef.current.style.setProperty('--pos', pos)
        }
      },3000);
    }
  }
  if(Array.isArray(title.value)){
    title = title[0]
  }
  if(Array.isArray(iconText.value)){
    iconText = iconText[0]
  }
  
  return (
    <div className={classes.root+(active?' '+classes.active:'') + ' '+ (bigAnimation?classes.bigAnimation:'')} onClick={onClick}>
      <div className={classes.top}>{iconText.value}</div>
      <div className={classes.middle + ' ' + (icon.type==='icon'?classes.icon:'') +  ' ' +(icon.id && classes[icon.id]?classes[icon.id]:'')}>{icon.value}</div>
      <div className={classes.bottom}>{title.value.replace('-', '-\u2060')}</div>     
    </div>
  );
}
export default CalculatorTile;