
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import i18n from '../i18n';
import classes from './CalculatorFinishView.module.css';
import okIcon from './../images/ok.png';

function CalculatorFinishView({className, footPrintResult}) {
  
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("/all.json")
      .then((response) => {
        return response.json()
      })
      .then((actualData) => {
        console.log(actualData)
        setTimeout(()=>setData(actualData), 3000);

        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        
        setData({score:0});
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  if(data == null){

  }
  

  let co2Gamms = Math.round(footPrintResult.co2);
  if(isNaN(co2Gamms))
    co2Gamms = 0
  let co2Kg = co2Gamms/1000;
  let co2Tons = co2Kg/1000;
  let co2 = co2Tons;
  let co2Text = "finish.text_1";
  if(co2Tons<1){
    co2 = co2Kg;
    co2Text = "finish.text_1_kg";
  }

  let total_co2Gamms = Math.round(data?.score?data.score:0);
  let total_co2Kg = total_co2Gamms/1000;
  let total_co2Tons = total_co2Kg/1000;
  let total_co2 = total_co2Tons;
  let total_co2Text = "finish.text_1";
  if(total_co2Tons<1){
    total_co2 = total_co2Kg;
    total_co2Text = "finish.text_1_kg";
  }
  
  let myPercent = co2Gamms/total_co2Gamms;
  let allPercent = .95;
  if(myPercent>allPercent)
  {
    myPercent = 0.95;
    allPercent = total_co2Gamms/co2Gamms;
  }
  
  
  if(!data?.score){
    total_co2=0;
    //co2=0;
    //myPercent=0;
    allPercent=0;
  }
  total_co2 = Math.round(total_co2*100)/100;
  const noEmission = co2===0;

  let cost = (co2Kg/1000)*30;

  cost = Math.round(cost*100)/100;
  if(i18n.language === "de")
    cost = cost.toFixed(2) + " €";
  else{
    cost /= 0.96;
    cost = (Math.round(cost*100)/100).toFixed(2);
    cost += " $";
  }

  co2 = Math.round(co2*100)/100;

  let videoPath = "";
  if(i18n.language === "de")
    videoPath = "/content/video_de.mp4";
  else
    videoPath = "/content/video_en.mp4";
  return (
  <>
    <div className={className + ' ' + classes.root + (!data?' '+classes.blur:'')}>
        <div className={classes.img}>
          <video src={videoPath} autoPlay={true} muted={true} loop={true}></video>

        </div>
        <p className={classes.paypal} dangerouslySetInnerHTML={{__html:i18n.t("finish.paypal")}}>{}</p>
        <span className={classes.label} dangerouslySetInnerHTML={{__html:i18n.t("finish.label_1")}}></span>
        <div className={classes.value+ ' '+classes.my + ' ' + (noEmission?classes.noEmission:'')} style={{'--size':Math.min(1,myPercent)}}>
          {
          noEmission?
            <span className={classes.value + ' '+classes.my} dangerouslySetInnerHTML={{__html:data?i18n.t(co2Text,{amount:co2.toLocaleString()}):""}}></span>
          :
            <span className={classes.value + ' '+classes.my} dangerouslySetInnerHTML={{__html:(data?(i18n.t(co2Text,{amount:co2.toLocaleString()})+" - "+ cost):"")}}></span>          
          }
           
         
          {noEmission && <img src={okIcon} alt="ok"></img>}
        </div>
        <span className={classes.label} dangerouslySetInnerHTML={{__html:i18n.t("finish.label_2")}}></span>
        <div className={classes.value}  style={{'--size':Math.min(1,allPercent)}}><span className={classes.value} dangerouslySetInnerHTML={{__html:data?i18n.t(total_co2Text,{amount:total_co2.toLocaleString()}):""}}></span></div>
        <p className={classes.text} dangerouslySetInnerHTML={{__html:i18n.t("finish.text_3")}}>{}</p>
        
    </div>
    <div className={classes.loading + ' '+ (data?classes.hidden:'')}>
      <div className={classes.spinner}></div>
      <span>{t("finish.calculating")}</span>
    </div>
  </>
  );
}
export default CalculatorFinishView;