import i18n from "i18next";
import { initReactI18next } from "react-i18next";

var userLang = navigator.language || navigator.userLanguage; 

let lang = userLang.split("-")[0].toLowerCase();
if(localStorage.getItem("language"))
  lang = localStorage.getItem("language");

if(lang != "de")
  lang = "en";

i18n
  .use(initReactI18next)
  .init({
    debug:false,
    resources: {
      de: {
        translation: {
          "de":"DE",
          "en":"EN",
        
          "intro_start_btn":"<b>CO<sub>2</sub>-Fußabdruck-Rechner</b> starten",
          "city":{
            "placeholder":"Eingabe Deiner Stadt..."
          },
          "menu":{
            "home":"Home",
            "footprint":"CO<sub>2</sub> Fußabdruck",
            "information":"Information",
            "languageSelect":"Sprachauswahl",
            "contact":"Kontakt",
            "imprint":"Impressum"

          },
          "finish":{
            "calculating":"Wird berechnet...",
            "title":"<b>Dein persönlicher CO<sub>2</sub>-Fußabdruck</b>",
            "label_1":"Dein persönlicher CO<sub>2</sub>-Fußabdruck",
            "text_1":"<b>{{amount}}</b> Tonnen CO<sub>2</sub>",
            "text_1_kg":"<b>{{amount}}</b> kg CO<sub>2</sub>",
            "label_2":"Durchschnittlicher CO<sub>2</sub>-Fußabdruck der Besucher",
            "text_2":"<b>{{amount}}</b> Tonnen CO<sub>2</sub>",
            "text_2_kg":"<b>{{amount}}</b> kg CO<sub>2</sub>",
            "text_3":`Bereits 1999 startete der ALRV das Projekt „Garten Eden“. Inzwischen stehen rund 400 Bäume und 4,6 Kilometer Hecke mit rund 30.000 Pflanzen. Es können auch Baum-Patenschaften auf dem CHIO Aachen-Gelände übernommen werden. <a href="https://www.chioaachen.de/de/chio-co/mehr-chio-aachen/#garten_eden" target="_blank">Hier gibt es weitere Infos.</a>`,
            "paypal":`<form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="GW8TQ25GJY6QC" />
            <input type="image" src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
            </form>`
          },
          "imprint":{
            "title":"<b>Impressum</b>",
            "text":``
          },
          "contact":{
            "title":"<b>Kontakt</b>",
            "text":`tbd`
          },
          "information":{
            "title":"<b>Information</b>",
            "text":`Gemeinsam entwickelt von SAP und dem CHIO Aachen, berechnet der CO²-Footprint-Rechner den individuellen CO²-Fußabdruck jedes Turnierbesuchers. Die aktuelle Version, die künftig weiter ausgebaut wird, beschränkt sich zunächst auf die Anreise zum Turnier. Aber schon jetzt hilft sie dabei, ein Bewusstsein für den CO²-Verbauch zu schaffen. Und: Sie bietet die (freiwillige) Möglichkeit, für das Baumpflanzprojekt Garten Eden zu spenden, um die Soers noch grüner werden zu lassen.<br>
			<br>
			Quellen der Daten:			

			<a href="https://calculator.carbonfootprint.com/calculator.aspx?lang=de&tab=3" target="_blank">www.calculator.carbonfootprint.com</a>

			<a href="https://klimaktiv.co2-rechner.de/de_DE/mobility-flight#panel-calc" target="_blank">www.klimaktiv.co2-rechner.de/de_DE</a>

			<a href="https://www.bmuv.de/" target="_blank">www.bmuv.de</a>`
          }
        },
        
      },
      en: {
        translation: {
          "de":"DE",
          "en":"EN",
         
          "intro_start_btn":"Start <b>CO<sub>2</sub> Footprint</b> Calculator",
          "city":{
            "placeholder":"Enter your city..."
          },
          "menu":{
            "home":"Home",
            "footprint":"CO<sub>2</sub> footprint",
            "information":"Information",
            "languageSelect":"Language select",
            "contact":"Contact",
            "imprint":"Imprint"

          },
          "finish":{
            "calculating":"Calculating...",
            "title":"<b>Your personal CO<sub>2</sub> footprint</b>",
            "label_1":"Your personal CO<sub>2</sub> footprint",
            "text_1":"<b>{{amount}}</b> Tons CO<sub>2</sub>",
            "text_1_kg":"<b>{{amount}}</b> kg CO<sub>2</sub>",
            "label_2":"Average CO<sub>2</sub> footprints of visitors",
            "text_2":"<b>{{amount}}</b> Tons CO<sub>2</sub>",
            "text_2_kg":"<b>{{amount}}</b> kg CO<sub>2</sub>",
            "text_3":`As early as 1999, the ALRV launched the "Garden Eden" project. In the meantime, there are about 400 trees and 4.6 kilometers of hedge with about 30,000 plants. Tree sponsorships can also be taken on at the CHIO Aachen grounds. <a href="https://www.chioaachen.de/en/chio-co-2/more-chio-aachen/#garden_eden" target="_blank">Here you can find more information.</a>`,
            "paypal":`<form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="GW8TQ25GJY6QC" />
            <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
            </form>`
          },
          "imprint":{
            "title":"<b>Imprint</b>",
            "text":``
          },
          "contact":{
            "title":"<b>Contact</b>",
            "text":`tbd`
          },
          "information":{
            "title":"<b>Information</b>",
            "text":`Jointly developed by SAP and the CHIO Aachen, the CO² Footprint Calculator calculates the individual CO² footprint of each tournament visitor. The current version, which will be further expanded in the future, is initially limited to travel to the tournament. But it is already helping to raise awareness of CO² consumption. And: it offers the (voluntary) opportunity to donate to the Garden of Eden tree planting project to make the Soers even greener.<br>
			<br>
			Data sources:
      
			<a href="https://calculator.carbonfootprint.com/calculator.aspx?lang=de&tab=3" target="_blank">www.calculator.carbonfootprint.com</a>

			<a href="https://klimaktiv.co2-rechner.de/de_DE/mobility-flight#panel-calc" target="_blank">www.klimaktiv.co2-rechner.de/de_DE</a>

			<a href="https://www.bmuv.de/" target="_blank">www.bmuv.de</a>`
          }
        },
      },
      
    },
    lng: lang,
    fallbackLng: "en",
  });

export default i18n;

export function SetLanguage(lang){
  localStorage.setItem("language", lang);
  i18n.changeLanguage(lang);
}