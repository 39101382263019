
import i18n from "i18next";
import classes from './CalculatorView.module.css';
import cclasses from './CalculatorViewContent.module.css';
import fclasses  from './CalculatorFinishView.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import HtmlElement from "../HtmlElement";
import { Link, useMatch, useNavigate  } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import CalculatorTile from "./CalculatorTile";
import { AppContext } from "../_contexts/AppContext";
import MapsView from "../view_maps/MapsView";
import { DB } from "../DB";

import finishedImg from './../images/finish.jpg';
import CalculatorFinishView from "./CalculatorFinishView";


import car from './../images/car_m.svg'
import cycle from './../images/cycle.svg'
import feet from './../images/feet.svg'
import flight from './../images/flight.svg'
import scooter from './../images/scooter.svg'
import train from './../images/train.svg'


const icons = {
  car:car,
  cycle:cycle,
  feet:feet,
  flight:flight,
  scooter:scooter,
  train:train
}
const parseContent = (input)=>{
  console.log(input)
  if(typeof input === 'string'){
    if(input.split('.').length===1 || icons[input.split(".")[0]] == null)
      return {type:'text', value:input};
    else{
      return {type:'icon', id:input.split(".")[0],value:<img src={icons[input.split(".")[0]]} alt="icon" />};      
    }    
  }

  if(typeof input === 'object' && input !== null && !Array.isArray(input)){
  
    if(input[i18n.language]!=null){
      return parseContent(input[i18n.language]);
    }
  }
  if(Array.isArray(input) && input !== null ){
    let entries = [];
    input.map(element=>{
      entries.push(parseContent(element));
    })
    return entries;
  }
  return {type:'text', value:JSON.stringify(input)};
}


function CalculatorView({step_id}) {
  const db = useContext(AppContext).db;
  //console.log(db.getActualStepId())

  const containerRef = useRef();
  let navigate = useNavigate();
  let stepDoneRoute = useMatch("/calculator/:step/done");

  const [render, setRender]=useState(0);
  const [state, setState]=useState({});
  const { t } = useTranslation();
  const mapsDone = (name, location)=>{   
    
    db.setResult(db.getActualStepId(), {name:name, location:location, distance:location.distance})
    setRender(new Date().getTime())
  }

  const mapsNext = ()=>{
    console.log("next", DB.getResult('city'));
    if(DB.getResult('city') && DB.getResult('city').distance != null &&  DB.getResult('city').name != null){
      nextStep();
    }
    
  }
  //console.log(stepDoneRoute,stepDoneRoute?.params?.step === 'travel_type', state.animationEntry)
  const tileSelected = (entry, animationDone=false)=>{
   
    if(!animationDone && db.getActualStepId() === 'travel_type'){
      setState({...state, animationEntry:entry});
      navigate("/calculator/travel_type/done");
      setTimeout(()=>{tileSelected(entry, true)}, 4000)
     
      return;    
    }
   
    db.setResult(db.getActualStepId(), {id:entry.id})
    db.setNextStep();
    if(!DB.isFinished())
      navigate("/calculator/"+db.getActualStepId())
    else
      navigate("/calculator/done")

  }
  const prevStep=()=>{
    db.setPrevStep();
    
    //console.log(db.getActualStepId())
    navigate("/calculator"+("/"+db.getActualStepId()))
    //setRender(new Date().getTime());
  }
  const nextStep=()=>{
    if(DB.isFinished())
    {
      db.reset();
      navigate("/")
    }else{
      db.setNextStep()
      setRender(new Date().getTime());
    }
    /*if(step>=7)
      return;
    setStep(step+1)*/
  }

  
  const stepData = db.getActualStep();
 // console.log(stepData)
 
 
  const stepResult = DB.getResult(stepData?.id);
  //console.log(stepData, stepResult)
  
  const stepTitle = DB.isFinished()?i18n.t("finish.title"):stepData?.title[i18n.language];

  let activeDot = stepData.GetMainId();
  if(DB.isFinished())
    activeDot = 3;

  const entries = stepData && stepData.options?stepData.options:[];
 
  let columns = 3;
  if(containerRef.current){
    
    const r = containerRef.current.getBoundingClientRect();
    const ratio = r.width / r.height;
   
    //if(r.width<801)
    {
      const minw = 120;
      //if(ratio<.3)
      //  columns = 1
      if(ratio<.5)
        columns = 2
      else if(ratio<1)
        columns = 3
      else if(ratio<1.5)
        columns = 4
      else if(ratio<1.8)
        columns = 5
      else
        columns = 6
      
    }
    if(entries.length>2)
      columns = Math.min(columns, entries.length)
  }
  
 //console.log(db.calculateTravel())
  return (
    <div className={classes.root}>      
      <div className={classes.header}>
        <div className={classes.dots+' ' +(activeDot>2?classes.finished:'')}>
          <div className={activeDot===0?classes.active:''}></div>
          <div className={activeDot===1?classes.active:''}></div>
          <div className={activeDot===2?classes.active:''}></div>
        </div>
        <h1 className={"mixed"} dangerouslySetInnerHTML={{__html:stepTitle}}></h1>
      </div>
      <div className={classes.content + ' ' +cclasses.root}  ref={containerRef}>
        
        <MapsView doneCallback={mapsDone} hidden={stepData.id !== 'city'} closeCallback={nextStep} nextCallback={mapsNext} model={stepData}></MapsView>
        {!DB.isFinished() &&  stepData.id !== 'city' &&
        <>
          <div className={cclasses.bg}></div>

          <div className={cclasses.container} style={{'--columns':columns}}>
            {
            entries.map((entry=>{
              return(
                <CalculatorTile key={entry.id} entry={entry} onClick={()=>tileSelected(entry)} active={entry.id === stepResult?.id}></CalculatorTile>              
              )}  
              ))
            }
          </div>
        </>
        }
        {DB.isFinished() &&  stepData.id !== 'city' &&
         <CalculatorFinishView className={cclasses.container} footPrintResult={db.calculateTravel()}></CalculatorFinishView>

        }
        {
        stepDoneRoute && stepDoneRoute?.params?.step === 'travel_type' && state.animationEntry && <div className={classes.animation}>
        <CalculatorTile bigAnimation={true} entry={state.animationEntry}></CalculatorTile>              
        
      </div>
      }
      </div>
      
      <div className={classes.footer}>
        <div className={classes.footerBtn + ' '+(db.getActualStepId()==='city'?classes.hidden:'')} onClick={prevStep}></div>
        {db.isLastStep() && <div>LOGO</div>}
        <div></div>
        <div className={classes.footerBtn + ' '+(db.isLastStep() || !db.isActualStepFinished()?classes.hidden:'')} onClick={nextStep}></div>
      </div>
    </div>
  );
}

export default CalculatorView;
