
export class Calculator{
  constructor(raw){
    this.raw = raw;

    this.distances = this.raw.distances;
    this.flight = this.raw.flight;
    this.car = this.raw.car;
    this.train = this.raw.train;
    this.feet = this.raw.feet;
    this.taxi = this.raw.taxi;
    this.stay = this.raw.stay;
    this.bus = this.raw.bus;
   
  }

  Calculate(state){


    const flightBruttoFactor = 1.1;
    const carBruttoFactor = 1.2; //roads are not direct lines
    const trainBruttoFactor = 1.3;

    let distance = -1;
    let co2 = 0;
    let distanceTo=0;
    let co2To=0;
    let distanceFrom=0;
    let co2From=0;

    let distanceStay=0;
    let carSetting = -1;
    for(let i in state.steps){
      if(state.steps[i] === 'city'){
        let res = state.results.city;
        if(res?.distance)
          distance = res?.distance;        
      }
      else  if(state.steps[i] === 'travel_type'){
        let res = state.results.travel_type;
        
        if(res?.id === "travel_type_feet"){
          co2 += this.calculateWay("feet", distance);
          return co2;
        }
        else if(res?.id === "travel_type_car"){
          if(
            state.results["car_persons"] && 
            state.results["car_engine"] && 
            state.results["car_age"] && 
            state.results["car_size"]){
              let engine = this.car.engines.find((eng)=>eng.id ===state.results["car_engine"].id);
              let ageFactor = this.car.age.find((eng)=>eng.id ===state.results["car_age"].id);
              let personsFactor = this.car.persons.find((eng)=>eng.id ===state.results["car_persons"].id);
              let sizeChar = state.results["car_size"].id.split("_")[state.results["car_size"].id.split("_").length-1];
              let _engine = {id:'engine', val:engine.val[sizeChar]};
              carSetting = _engine.val * ageFactor.val * personsFactor.val
              co2+= carSetting*(distance*carBruttoFactor);
          }
        } 
        else if(res?.id === "travel_type_flight"){
          co2+= this.calculateWay("flight", distance*flightBruttoFactor);
        }
        else if(res?.id === "travel_type_train"){
          co2+= this.calculateWay("train", distance*trainBruttoFactor);
        }               
      }
      else  if(
        state.steps[i] === 'travel_type_flight_to' ||
        state.steps[i] === 'travel_type_train_to'
        ){
        let res = state.results[state.steps[i]];
        if(res){
          let parts = res?.id.split("_");
          distanceTo = this.getDefaultDistance(res?.id)+(res?.id.indexOf("_train")>-1?trainBruttoFactor:0);
          co2To = this.calculateWay(parts[parts.length-1], distanceTo);           
        }
      }
      else  if(
        state.steps[i] === 'travel_type_flight_from' ||
        state.steps[i] === 'travel_type_train_from'
        ){
       
        let res = state.results[state.steps[i]];
        if(res){
          let parts = res?.id.split("_");
          distanceFrom = this.getDefaultDistance(res?.id)+(res?.id.indexOf("_train")>-1?trainBruttoFactor:0);
          co2From = this.calculateWay(parts[parts.length-1], distanceFrom);
        }    
      }if(state.steps[i] === 'stay_days'){
        let res = state.results.stay_days;
        if(res?.id){
        
          if(state.results.stay_travel){
            let trips = this.getDefault("stay", res.id);
            let stay_travel = state.results.stay_travel.id;
            
            let oneDistance = this.getDefaultDistance(stay_travel);
            
            let stayDistance = oneDistance*trips;
            distance += stayDistance;

            let __co2 = 0;
            if(state.results.stay_travel?.id === 'stay_travel_car'){
              if(carSetting>-1){
                __co2 = carSetting * stayDistance;
                co2 += __co2;
              }else{
                __co2 = this.calculateWay("taxi", stayDistance) ;
                co2 += __co2;
              }
              
            }else if(state.results.stay_travel?.id === 'stay_travel_bus'){
              __co2 = this.calculateWay("bus", oneDistance)*trips;
              co2 += __co2;             
            }            
          }
        }
      }
    }

   /* console.log("Distance", distance)
    console.log("to",distanceTo, co2To)
    console.log("from", distanceFrom,co2From )
    console.log("co2", co2 )
    */
    return {co2:co2, distance:distance, distanceTo:distanceTo, distanceFrom:distanceFrom, distanceStay:distanceStay, data:state};
  }

  calculateWay(type, distance){
    if(this[type] == null)
      return 0;
    for(let i in this[type]){
      if(this[type][i].limit === 0 || this[type][i].limit>distance)
        return this[type][i].val*distance;
    }

    return 0;
  }

  getDefaultDistance(id){
    
    if(this.distances[id])
      return this.distances[id];
    let parts = id.split("_");
    if(parts.length==1)
      return 0;
    parts.splice(parts.length-1,1);
    
    return this.getDefaultDistance(parts.join("_"));
  }

  getDefault(key, id){

    
    if(!this[key])
      return 0;
    for(let i in this[key]){
      if(this[key][i].id === id)
        return this[key][i].val;
    }
      

    let parts = id.split("_");
    if(parts.length===1)
      return 0;
    parts.splice(parts.length-1,1);
    
    return this.getDefault(parts.join("_"));
  }
}

