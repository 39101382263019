
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import classes from './TextView.module.css';




function TextView({type}) {
  const stepTitle =i18n.t(type+".title");
  let navigate = useNavigate();

  
  return (
    <div className={classes.root}>      
      <div className={classes.header}>
        <div className={classes.dots + ' ' + classes.finished}></div>
        <h1 className={"mixed"} dangerouslySetInnerHTML={{__html:stepTitle}}></h1>
        <div className={classes.close} onClick={()=>{navigate("/calculator")}}></div>
      </div>
      <div className={classes.content} dangerouslySetInnerHTML={{__html:i18n.t(type+".text")}}>        
      </div>
      <div className={classes.footer}></div>
    </div>
  );
}

export default TextView;
