
import i18n from "i18next";
import classes from './Header_2022.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import { SetLanguage } from "../i18n";

import menuImg from "./../images/header_2022/i_icon.svg";
import closeImg from "./../images/header_2022/close_icon.svg";
import chioLogo from "./../images/header_2022/chio_logo.svg";
import sapLogo from "./../images/header_2022/SAP_log_header.svg";

import mercedesLogo from "./../images/header_2022/mercedes.svg";
import allianzLogo from "./../images/header_2022/allianz.svg";
import turkishLogo from "./../images/header_2022/turkish_airlines.svg";
import rolexLogo from "./../images/header_2022/rolex_log.svg";

import Drawer from "../view_drawer/Drawer";
import { useState } from "react";

function Header_2022() {
  const { t } = useTranslation();
  const [drawerOpened, setDrawerOpened] = useState(false)
 
  const toggleDrawer = ()=>{
    setDrawerOpened(!drawerOpened)
  }

  const closeDrawer = ()=>{
    setDrawerOpened(false)
  }
  

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.row}>
          <img className={classes.chio} src={chioLogo} alt="CHIO" />
          <span>CHIO AACHEN</span>
          <img className={classes.sap} src={sapLogo} alt="SAP" />
        </div>
        <div className={classes.row}>
          <img className={classes.mercedes} src={mercedesLogo} alt="Mercedes Benz" />
          <img className={classes.allianz} src={allianzLogo} alt="Allianz" />
          <img className={classes.ta} src={turkishLogo} alt="Turkish Airlines" />
          <img className={classes.rolex} src={rolexLogo} alt="ROLEX" />
        </div>
      </div>
      
      <Drawer opened={drawerOpened} closeCallback={closeDrawer}></Drawer>
      <div className={"btn_circle " + classes.menuBtn} onClick={toggleDrawer}><img src={!drawerOpened?menuImg:closeImg} /></div>      
    </div>
  );
}

export default Header_2022;
